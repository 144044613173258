.metronome {
    text-align: center;
    max-width: 400px;
    margin: 0 auto;
    padding: 30px;
}

.bpm-slider input {
    width: 100%;
    margin: 16px;
}

.metronome button {
    background: #3341FE;
    color: white;
    padding: 16px;
    border-radius: 4px;
    width: 100px;
    font-size: 18px;
    border: none;

}